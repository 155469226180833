
select{
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
  border-radius: 5px;
  border: 1px solid #bdbdbd;

}

.text-field__label {
  display: block;
  margin-bottom: 0.25rem;
}

.text-field__input {
  display: block;
  
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #292121;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.text-field__input[type="search"]::-webkit-search-decoration,
.text-field__input[type="search"]::-webkit-search-cancel-button,
.text-field__input[type="search"]::-webkit-search-results-button,
.text-field__input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.text-field__input::placeholder {
  color: #212529;
  opacity: 0.4;
}

.text-field__input:focus {
  color: #212529;
  background-color: #fff;
  border-color: #bdbdbd;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.25);
}

.text-field__input:disabled,
.text-field__input[readonly] {
  background-color: #f5f5f5;
  opacity: 1;
}

/* with floating label */
.text-field_floating-2 {
  position: relative;
}

.text-field_floating-2 .text-field__input {
  height: calc(2.5rem + 2px);
  padding: 0.5rem 0.75rem;
}

.text-field_floating-2 .text-field__label {
  position: absolute;
  top: 50%;
  left: 0.3125rem;
  display: flex;
  transform: translateY(-50%);
  pointer-events: none;
  border: none;
  background-color: #fff;
  color: #757575;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  transition: top 0.1s ease-in-out, scale 0.1s ease-in-out;
}

.text-field_floating-2 .text-field__input::-moz-placeholder {
  color: transparent;
}

.text-field_floating-2 .text-field__input::placeholder {
  color: transparent;
}

.text-field_floating-2 .text-field__input:focus~.text-field__label,
.text-field_floating-2 .text-field__input:not(:placeholder-shown)~.text-field__label {
  top: 0;
  transform: translateY(-50%) scale(0.75);
}



.group-s{ 
  position:relative; 
  margin-bottom:45px; 
}
.input-s{
  font-size:18px;
  /* padding:10px 0px 5px 5px; */
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  display:block;
  width:300px;
  border:none;
  border:1px solid #757575;
  border-radius: 10px;
}
.input-s:focus{ outline:none; }

.label-s{
  
  color:#999; 
  font-size:18px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:5px;
  top:10px;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}
/* active state */
.input-s:focus ~ label, input:valid ~ label 		{
  top:-20px;
  font-size:14px;
  color:#5264AE;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}

.button-danger {
  background-color: #c73e3c;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.button-danger:hover,
.button-danger:focus {
  background-color: rgb(163, 18, 18);
}

.button-danger:focus {
  box-shadow: 0 0 0 4px rgba(60, 64, 67, 0.15);
}

.button-danger:active {
  background-color: #592222;
  box-shadow: none;
}


.button-cancel {
    background-color: #5d6469;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.15385;
    margin: 0;
    outline: none;
    padding: 8px .8em;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;
  }

  .button-cancel:hover,
  .button-cancel:focus {
    background-color: rgb(44, 47, 50);
  }

  .button-cancel:focus {
    box-shadow: 0 0 0 4px rgba(60, 64, 67, 0.15);
  }

  .button-cancel:active {
    background-color: #040f19;
    box-shadow: none;
  }

  
  .button-s {
    background-color: #d0e2f1;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #3b1e1e;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.15385;
    margin: 0;
    outline: none;
    padding: 8px .8em;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;
  }

  .button-s:hover,
  .button-s:focus {
    background-color: #c1d3e2;
  }

  .button-s:focus {
    box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
  }

  .button-s:active {
    background-color: #c3dffe;
    box-shadow: none;
  }





.textarea-1 {
  
  padding: 8px;
  
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px #ddd;
  font-size: 16px;
  color: #444;
}

.input-font-size {
 
  padding: 1px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px #ddd;
  font-size: 16px;
  color: #444;
  width: 40px;
}

.input-size {
 
  padding: 1px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px #ddd;
  font-size: 16px;
  color: #444;
  width: 80px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.hr-line {
	margin: 20px 0;
	padding: 0;
	height: 0;
	border: none;
	border-top: 1px solid #333;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
